import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ACRButton from '../ACRButton';
import { incidentsContext } from '../../contexts/IncidentsContext';

export default function ReportDamage({ incidentIndex }) {
  const { incidents } = useContext(incidentsContext);

  return (
    <div>
      <Grid
        container
        xs={12}
        alignSelf=''
        justifyContent='center'
        sx={{
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          bgcolor: '#D0EDFC!important',
        }}
      >
        <Grid item justifySelf='center'>
          {incidents[incidentIndex].urlName == 'debby' ?
            (
              <>
              <Typography variant='h4' pb={4}>
              Click the link for FEMA & Small Business Administration resources{''}:
            </Typography>
            <ACRButton
            item
              text='Apply for FEMA Assistance Here'
              link="https://www.disasterassistance.gov/" //{incidents[incidentIndex].damageReportURL}
              size='jumbo'
              externalLink={true}
            />
            </>) :
            (
              <>
              <Typography variant='h4' pb={4}>
              Report Home and Property Damage Here:{' '}
            </Typography>
            <ACRButton
              text='Report Damage'
              link={incidents[incidentIndex].damageReportURL} //{incidents[incidentIndex].damageReportURL}
              size='jumbo'
              externalLink={true}
            />
            </>)
          }
          {/* <ACRButton
            text='Report Damage'
            link="https://www.disasterassistance.gov/" //{incidents[incidentIndex].damageReportURL}
            size='jumbo'
            externalLink={true}
          /> */}
        </Grid>
      </Grid>
    </div>
  );
}
