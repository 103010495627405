import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { incidentsContext } from '../contexts/IncidentsContext';
import { useContext } from 'react';


export default function redirectURL(incidentsContext) {

    let urlsToRedirect = [];
    let incidents = Object.values(incidentsContext)[0];

    if (incidents && incidents.length > 0) {
        incidents.forEach((incident) => {
            if (incident.aliases) {

                let url;
                let redirectURL = `/incidents/${incident.urlName}`;

                incident.aliases.forEach((alias) => {
                    url = `/incidents/${alias}`
                    url = url.replace(/\s/g, '').toLowerCase();

                    let urlDetails = {
                        redirectUrl: redirectURL,
                        url: url,
                    }
                    urlsToRedirect.push(urlDetails)
                })
            }
        }
        )
    }

    const RedirectWithId = (urlDetails) => {
        const { id } = useParams();
        return <Navigate key={urlDetails.id} to={`${urlDetails.urlDetails.redirectUrl}/${id}`} replace />;
    };
    return (
        <Routes>
            {urlsToRedirect.map((urlDetails => (
                <Route key={urlDetails.id} path={urlDetails.url}
                    element={<Navigate to={urlDetails.redirectUrl} />} />  
                    
            )))}

            {urlsToRedirect.map((urlDetails => (
                 <Route key={urlDetails.id} path={`${urlDetails.url}/:id`} element={<RedirectWithId urlDetails={urlDetails} />}  />   
                    
            )))}

        </Routes>
    )
}
